import 'ress';
import './bootstrap.scss';
import './base.scss';

export const cssModules = [
  require.context('./core', false, /\.scss$/),
  require.context('./fonts', false, /\.scss$/),
  require.context('./layout', false, /\.scss$/),
  require.context('./form', false, /\.scss$/),
].reduce((array, context) => [
  ...array,
  ...context.keys().map(file => {
    const { css } = context(file);
    return css && css.locals;
  }),
], []);
