import { createElement, useState, useCallback } from 'react';
import { createPortal } from 'react-dom';
import { each } from 'lodash';
import * as components from '.';

export function App() {
  const [ app, setApp ] = useState(bootstrap);

  const updateApp = useCallback(data => {
    setApp(app => ({
      ...app,
      ...data,
    }));
  }, []);

  return app.containers.map(({ Component, container, ...props }) =>
    createPortal(createElement(Component, { ...props, app, container, updateApp }), container)
  );
}

function bootstrap() {
  const containers = [];
  for (const key in components) {
    if (components[key].selector) {
      each(window.document.querySelectorAll(components[key].selector), container => {
        containers.push({
          container,
          Component: components[key],
          innerHTML: container.innerHTML,
        });
        container.innerHTML = '';
      });
    }
  }
  return { containers };
}
