import { useEffect, useRef } from 'react';
import { useEvent, useRafLoop } from 'react-use';

export function ParallaxObject({ container }) {
  if ('ontouchstart' in window) {
    //disable parallax for touch devices
    return null;
  }
  const pageYOffset = useRef();
  useEffect(() => {
    pageYOffset.current = window.pageYOffset;
  }, []);
  useEvent('scroll', () => {
    pageYOffset.current = window.pageYOffset;
  });
  useRafLoop(() => {
    if (pageYOffset.current) {
      container.style.transform = `translate3d(0, ${pageYOffset.current*container.getAttribute('data-parallax-value')}px, 0)`;
      pageYOffset.current = null;
    }
  });
  return null;
}

ParallaxObject.selector = '[data-parallax-value]';
