import { useEffect} from 'react';
import { each } from 'lodash';
import { useEvent } from 'react-use';

export function Tabs({ container }) {
  const tabs = container.parentNode;

  useEffect(() => {
    if (tabs.children[0]) {
      tabs.classList.remove('loading');
      tabs.children[0].classList.add('visible');
    }
  }, []);

  useEvent('click', event => {
    const [ match, id ] = /^#([\w\d-_]+)$/.exec(event.target.getAttribute('href')) || [];
    if (match && tabs.querySelector(match)) {
      event.preventDefault();
      each(tabs.children, tab => {
        tab.classList.toggle('visible', tab.id == id);
      });
      tabs.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  })

  return null;
}

Tabs.selector = 'tabs-controller';
